<script>
import { required, email } from "vuelidate/lib/validators";
import UserModel from "../../../models/user";
import { authMethods, notificationMethods } from "@/state/helpers";

export default {
  data() {
    return {
      user: UserModel,
      submitted: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    user: {
      email: { required, email },
      password: { required },
    },
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.tryingToLogIn = true;
        // Reset the authError if it existed.
        this.authError = null;
        return (
          this.logIn({
            email: this.user.email,
            password: this.user.password,
          })
            // eslint-disable-next-line no-unused-vars
            .then((token) => {
              this.tryingToLogIn = false;
              this.isAuthError = false;
              // Redirect to the originally requested page, or to the home page
              this.$router.push(
                this.$route.query.redirectFrom || { name: "home" }
              );
            })
            .catch((error) => {
              this.error(error.response.data.error);
              this.tryingToLogIn = false;
              this.authError = error ? error : "";
              this.isAuthError = true;
            })
        );
      }
    },
    resetPasssword(){
      console.log("reset password!")
    }
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-7">
            <div class="authentication-bg">
              <img src="@/assets/images/login-img.png" alt="" />
            </div>
          </div>
          <div class="col-lg-5">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/logo.jpeg"
                              height="74"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                        <p class="text-muted">Sign in to continue to Sheesh.</p>
                      </div>

                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                        >{{ notification.message }}</b-alert
                      >

                      <div class="p-2 mt-5">
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToLogIn"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                              type="email"
                              v-model="user.email"
                              class="form-control"
                              id="email"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.user.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.user.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.user.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.user.email"
                                >Please enter valid email.</span
                              >
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Password</label>
                            <input
                              v-model="user.password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.password.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.user.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                          </div>
                          
                          <div class="row">
                          <div class="col-6 pl-5">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                              >Remember me</label
                            >
                      
                          </div>
                          <div class="col-6">
                          <router-link
                            tag="a"
                            to="/forgot-password"
                            class="restPassword"
                            >Forget password?</router-link
                          >
                          </div>
                          </div>

                        

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-danger w-md waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>

                        
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          © 2021 Sheesh. Developed
                          <i class="mdi mdi-heart text-danger"></i> by
                          MVP-APPS.AE
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.restPassword{
  float:right;
  margin-right:10px;
}
.restPassword:hover{
  cursor:pointer;
}
</style>

